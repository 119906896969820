<template>
  <b-row>
    <b-col cols="12">
      <h-card class="mt-4 h-100">
        <b-row>
          <b-col cols="12" class="d-flex flex-column mt-5 mt-lg-0">
            <h4 class="mt-0 mb-0">
              {{
                messageRedactionIA
                  ? $t('messages.generatedMessagesTitle')
                  : $t('messages.yourMessagesRedaction')
              }}
            </h4>
            <h6 class="mt-4">
              {{
                messageRedactionIA
                  ? $t('messages.messagesContentIaExplain')
                  : $t('messages.messagesContentManualExplain')
              }}
            </h6>
            <div
              v-for="(message, index) in messagesContent"
              :key="index"
              class="my-2 d-flex align-items-center"
            >
              <b-form-checkbox
                :checked="message.selected"
                @change="updateSelected(index, $event)"
                :disabled="messageGenerationState === 'running'"
                name="check-button"
                class="mr-3"
                switch
              >
              </b-form-checkbox>
              <textarea
                :class="{ 'border-selected': messagesContent[index].selected }"
                :value="message.text"
                @input="updateText(index, $event.target.value)"
                class="w-100 h-100"
                :disabled="
                  messageGenerationState === 'running' ||
                    !messagesContent[index].selected
                "
                rows="4"
              ></textarea>
            </div>
          </b-col>
        </b-row>
        <b-col cols="12" v-if="messageRedactionIA">
          <div class="d-flex justify-content-center mt-5">
            <h-button
              v-if="messageGenerationState === 'done'"
              :disabled="messageGenerationState === 'running'"
              @click="generateMessage()"
              blue
            >
              <b-icon icon="chat-dots-fill" class="w-17px mr-2" font-scale="1">
              </b-icon>
              {{ this.$t('messages.regenerateMessags') }}
            </h-button>
          </div>
        </b-col>
      </h-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      selectedToneValue: null,
      selectedPublicValue: null,
    }
  },
  mounted() {
    this.$webSocketsConnect()
    const isMessagesGenerated = this.currentMessage.messagesContent.length
      ? this.currentMessage.messagesContent[0].text
      : ''
    if (isMessagesGenerated && isMessagesGenerated.length > 50) return
    if (this.messageRedactionIA) this.generateMessage()
    else this.manualMessage()
  },
  methods: {
    ...mapMutations('message', {
      updateMessageText: 'UPDATE_MESSAGE_TEXT',
      updateMessageSelected: 'UPDATE_MESSAGE_SELECTED',
    }),
    updateSelected(index, selected) {
      this.updateMessageSelected({ index, selected })
    },
    updateText(index, text) {
      this.updateMessageText({ index, text })
    },
    generateMessage() {
      if (this.messageGenerationState === 'running') return
      this.messagesContent = Array.from({ length: 5 }, () => ({
        text: this.$t('messages.generationMessageWait'),
        selected: false,
      }))

      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageGenerationState',
        value: 'running',
      })

      const promptData = JSON.stringify({
        Content: {
          IdTransaction: this.$store.state.message.newMessage.idTransaction,
          Parameters: {
            product: {
              name: this.currentMessage.productDescription,
              features: this.currentMessage.keywords.split(','),
              price: this.currentMessage.price,
              target: this.currentMessage.target.value,
            },
            generation: {
              model: 'TestPat',
              count: '5',
            },
          },
          Provider: 'MessageWritingHelper',
        },
        Header: {
          Type: 'ProviderRequest',
          idbrand: this.$store.state.user.user.visibleBrands[0].id,
        },
      })
      this.$webSocketsSend(promptData)
    },
    manualMessage() {
      this.messagesContent = Array.from({ length: 5 }, () => ({
        text: '',
        selected: true,
      }))
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageGenerationState',
        value: 'done',
      })
    },
  },
  computed: {
    ...mapState({
      messageGenerationState: (state) =>
        state.message.newMessage.messageGenerationState,
      currentMessage: (state) => state.message.newMessage,
      messageRedactionIA: (state) =>
        !state.message.newMessage.messageRedactionManual,
    }),
    messagesContent: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.messagesContent
      },
      // setter
      set: function(message) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messagesContent',
          value: message,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid #ebeff6;
  padding: 8px;
  transition: 0.3s;
  border-radius: 10px;
  resize: none;
  &.border-selected {
    border: 1px solid #f03c1f;
  }
  &::placeholder {
    color: #a8aeb9;
  }
}
</style>
